.reminders h3 form {
  display: inline-block;
  margin-left: .5rem;
}

.reminders form input[type="text"] {
  margin-right: 5px;
  font-size: 1rem;
  width: 300px;
}

.reminders form button[type="submit"] {
  font-size: .8rem;
  padding: .4rem 1rem;
}

.reminders .btn {
  padding: .5rem 1rem;
}

.reminder-group {
  margin-bottom: 2rem;
}

.reminder-item {
  display: flex;
  margin-bottom: .5rem;
}

.reminder-item .label {
  flex: 1 1 auto;
}

.reminder-item .delete {
  flex: 0 1 auto;
  padding: 0 5px;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}
