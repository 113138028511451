.home-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.home-container > section {
  flex: 1 1 50%;
  margin-top: 2rem;
}
